
#header {
  padding-top: .5rem;
  padding-bottom: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  #enter-btn {
    white-space: nowrap;
    height: 2em;
  }

  #streamer-name {
    margin-left: auto;
    font-size: 100%;
    line-height: 1.5;
  }
  #user-input {
    margin-right: 2em;
  }
}

@media screen and (max-width: 767px) {
  #header {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}
@media screen and (max-width: 440px) {
  #header {
    flex-flow: row wrap;
  }
}
