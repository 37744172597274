.tc-user-card {
  position: absolute;
  top: 30%;
  left: 0;
  background: #18181b;
  width: 50vw;
  max-height: 350px;
  border: 1px solid #adadb8;

  > div {
    padding: .5em 10px .5em 1em;
  }

  .tc-user-card-header {
    background: #35363a;

    a {
      color: #fefefe;
      text-decoration: none;
    }

    span {
      cursor: pointer;
    }
  }

  .tc-user-card-body {
    height: 20%;

    span {
      background-size: 1.25rem;
      background-repeat: no-repeat;
      border-radius: 0.2rem;
      display: inline-block;
      min-width: 20px;
      height: 20px;
      margin: 0 0.1rem 0.2rem 0;
      background-position: center;
      vertical-align: middle;
    }

    button {
      margin-right: 5px;
    }

    .btn-twitch {
      background-color: #764eb2;
      color: #f9f5ff;
      border: none;

      span {
        background-image: url("images/twitch.png");
      }

      &:hover {
        background-color: #8f5ed8;
      }
    }

    .btn-ban {
      span {
        background-image: url("images/ban.png");
      }
    }

    .btn-timeout {
      span {
        background-image: url("images/timout.png");
      }
    }
  }

  .tc-user-card-msgs {

    padding: 0;

    .tc-user-card-msgs-header {
      font-size: 70%;
      padding: .5em;
      background-color: #35363a;
      position: relative;
      top: 7px;
      cursor: pointer;
    }

    .chat-list {
      height: 0;
    }

    &.expanded .chat-list {
      height: 200px;
    }
  }
}


@media screen and (max-width: 430px) {
  .tc-user-card {
    width: 100vw;
    .tc-user-card-msgs {
      &.expanded .chat-list {
        height: 100px;
      }
    }
  }
}

@media screen and (max-width: 844px) {
  .tc-user-card {
    .tc-user-card-msgs {
      &.expanded .chat-list {
        height: 90px;
      }
    }
  }
}
