.tc-zero-width-emote {
  pointer-events: none!important;
  grid-row-start: 1;
  grid-column-start: 1;

}
.tc-zero-width-emote-wrapper {
  align-items: center;
  justify-items: center;
  display: inline-grid;
  vertical-align: middle;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .tc-emote {
    grid-row-start: 1;
    grid-column-start: 1;
  }
}

.emote-overlay-wrapper {
  pointer-events: none!important;
}
