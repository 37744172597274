.live-indicator {
  height: 0;
  position: relative;
  top: -9px;
  font-size: 75%;
}

.tc-live-info {
  background-size: 1.25rem;
  background-repeat: no-repeat;
  border-radius: 0.2rem;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  margin: 0 0.3rem 0.2rem 0;
  background-position: center;
  vertical-align: middle;
}

.tc-category-icon {
  background-image: url("images/category.png");

}
.tc-viewers-icon {
  background-image: url("images/viewers.png");

}
.tc-uptime-icon {
  background-image: url("images/uptime.png");

}
