html, body, #root, .App {
  height: calc(100vh - 50px);
  width: 100%;
}
body, #root, .App {
  height: 100%;
  width: 100%;
}
#root, .App {
  overflow: auto;
  background-color: #18181b!important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ml-auto {
  margin-left: auto!important;
}
.mr-auto {
  margin-right: auto!important;
}
#tc-replying-to {
  position: absolute;
  width: 50%;
  background: #35363a;
  right: 3px;
  bottom: 50px;
  padding: 0 .5em .5em;
}
#reply-title {
  font-size: 70%;
}
#reply-close-btn {
  margin-top: 5px;
  cursor: pointer;
  float: right;
  font-size: 70%;
}


.tooltip-inner {
  max-width: none!important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #18181b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
