
span.chat-badge {
    background-size: 1.4rem;
    background-repeat: no-repeat;
    border-radius: 0.2rem;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    margin: 0 0.3rem 0.2rem 0;
    background-position: center;
    vertical-align: middle;
    &.tooltip-badge {
        background-size: 72px;
        width: 72px;
        height: 72px;
    }
}

.chat-list span.tc-timestamp {
    background-color: transparent!important;
}

span.chat-badge.followed {
    background-image: url("images/heart.png");
    &.tooltip-badge {
        background-image: url("images/heartx2.png");
    }
}

.chat-badge.followed {
    color: rgb(242, 240, 237);
    background-color: rgb(60, 0, 165);
}


span.chat-badge.mod {
    background-image: -webkit-image-set(url(https://cdn.frankerfacez.com/static/badges/twitch/2/moderator/1/2.png) 2x, url(https://cdn.frankerfacez.com/static/badges/twitch/2/moderator/1/4.png) 4x);
    &.tooltip-badge {
        background-image: url("https://cdn.frankerfacez.com/static/badges/twitch/2/moderator/1/4.png");
    }
}

.chat-badge.mod {
    color: rgb(242, 240, 237);
    background-color: rgb(0, 139, 0);
}

span.chat-badge.vip {
    background-image: -webkit-image-set(url(https://cdn.frankerfacez.com/static/badges/twitch/2/vip/1/2.png) 2x, url(https://cdn.frankerfacez.com/static/badges/twitch/2/vip/1/4.png) 4x);
    &.tooltip-badge {
        background-image: url("https://cdn.frankerfacez.com/static/badges/twitch/2/vip/1/4.png");
    }
}

span.chat-badge.subscriber {
    background-image: -webkit-image-set(url(https://cdn.frankerfacez.com/static/badges/twitch/2/subscriber//1/2.png) 2x, url(https://cdn.frankerfacez.com/static/badges/twitch/2/subscriber//1/4.png) 4x);
    &.tooltip-badge {
        background-image: url("https://cdn.frankerfacez.com/static/badges/twitch/2/subscriber/1/4.png");
    }
}

.chat-badge.vip {
    color: rgb(255, 255, 255);
    background-color: rgb(224, 5, 185);
}

.chat-badge.subscriber {
    color: rgb(255, 255, 255);
    background-color: rgb(249, 181, 8);
}
.chat-badge.staff {
    color: rgb(242, 240, 237);
    background-color: rgb(61, 68, 70);
}

span.chat-badge.staff {
    background-image: -webkit-image-set(url(https://cdn.frankerfacez.com/static/badges/twitch/2/staff/1/2.png) 2x, url(https://cdn.frankerfacez.com/static/badges/twitch/2/staff/1/4.png) 4x);
    &.tooltip-badge {
        background-image: url("https://cdn.frankerfacez.com/static/badges/twitch/2/staff/1/4.png");
    }
}

span.chat-badge.broadcaster {
    background-image: -webkit-image-set(url(https://cdn.frankerfacez.com/static/badges/twitch/2/broadcaster/1/2.png) 2x, url(https://cdn.frankerfacez.com/static/badges/twitch/2/broadcaster/1/4.png) 4x);
    &.tooltip-badge {
        background-image: url("https://cdn.frankerfacez.com/static/badges/twitch/2/broadcaster/1/4.png");
    }
}

.chat-badge.broadcaster {
    color: rgb(255, 255, 255);
    background-color: rgb(233,25,22);
}

span.chat-badge.founder {
    background-image: -webkit-image-set(url(https://static-cdn.jtvnw.net/badges/v1/511b78a9-ab37-472f-9569-457753bbe7d3/2) 2x, url(https://static-cdn.jtvnw.net/badges/v1/511b78a9-ab37-472f-9569-457753bbe7d3/3) 4x);
    &.tooltip-badge {
        background-image: url("https://static-cdn.jtvnw.net/badges/v1/511b78a9-ab37-472f-9569-457753bbe7d3/3");
    }
}


span.chat-badge.topchatter {
    background-image: url("images/tc.png");
    &.tooltip-badge {
        background-image: url("images/tc2x.png");
    }
}

.chat-badge.topchatter {
    color: rgb(255, 255, 255);
    background-color: black;
}


span.chat-badge.spotlight {
    background-image: url("images/mag.png");
    &.tooltip-badge {
        background-image: url("images/mag2x.png");
    }
}

.chat-badge.spotlight {
    background-color: gray;
}



span.chat-badge.mention {
    background-image: url("images/mention.png");
    &.tooltip-badge {
        background-image: url("images/mention2x.png");
    }
}

.chat-badge.mention {
    background-color: #bc3d2c;
}
