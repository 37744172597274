.tc-scroll-pauser {
  cursor: pointer;
  position: relative;
  top: 2em;
}

.tc-modal {
  .modal-header {
    .btn-close:after {
      content: "\2716";
    }
  }

  .modal-content {
    background-color: #18181b;
    color: white;

    .modal-body {
      height: 50vh;

      .chat-list {
        height: auto;
      }
    }
  }

  &.tc-no-user-found {
    .modal-content {
      height: 25%;
    }
  }
}

.tc-welcome-display {
  margin: 10% 30% 0;

  #streamer-input {
    width: 40vw;
    margin-right: 1em;
  }

  small {
    font-size: 70%;
    color: #adadb8;
  }
}
.tc-header-chat-options {
  label {
    white-space: nowrap;
  }
}

#user-input {
  input {
    margin-right: .5em;
  }

  .react-autocomplete-input li {
    color: #18181b;

    &.active {
      color: #fff;
    }
  }
}

#tc-footer {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #35363a;

  .tc-button-wrapper {
    position: relative;
    top: 6px;
    right: 7px;
    float: right;
  }

  .btn-twitch {
    background-color: #764eb2;
    color: #f9f5ff;

    &:hover {
      background-color: #8f5ed8;
    }
  }

  #tc-user-img {
    height: 50px;
  }

  #tc-user-wrapper {
    max-width: 49%;
  }

  #tc-user-name {
    margin: auto 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .spinner-border {
    margin: 10px 50%;
  }

  #tc-messenger {
    float: right;
    height: 47px;
    background: #3d3d40;
    border: none;
    color: #f3f3f3;
    width: 50%;
    resize: none;
    line-height: 22px;
    padding-left: 5px;
    margin-right: 3px;
    display: inline;
    &:focus {
      outline: none;
    }
  }

  .react-autocomplete-input {
    background: #3d3d40;
  }

  #tc-messenger-emote-selector {
    position: fixed;
  }
}


@media screen and (max-width: 844px) {
  .tc-welcome-display {
    margin: 5% 5% 0;
  }
}
