.chat-list {
  height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;

  li {
    text-align: left;
    border: none;

    .username {
      cursor: pointer;
    }

    .tc-reply {
      font-size: 90%;
      color: #adadb8;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
  }

  .tc-msg {
    background-color: #18181b;

    &.tc-ftc {
      box-shadow: inset 10px 0 0 0 darkmagenta;
      border-radius: 0;
      padding-left: 10px!important;
    }
    &.tc-mention {
      box-shadow: inset 10px 0 0 0 #bc3d2c;
      border-radius: 0;
      padding-left: 10px!important;
    }
    &.tc-mention.tc-ftc {
      box-shadow: inset 10px 0 0 0 #bc3d2c, inset 20px 0 0 0 darkmagenta;
      padding-left: 20px!important;
    }
    &.tc-issue-red {
      box-shadow: inset 0 20px 8px -10px #C70039, inset 0px -20px 8px -10px #C70039;
    }
    &.tc-issue-orange {
      box-shadow: inset 0 15px 8px -10px #FF5733, inset 0px -15px 8px -10px #FF5733;
    }
    &.tc-issue-yellow {
      box-shadow: inset 0 11px 8px -10px #FFC300, inset 0px -11px 8px -10px #FFC300;
    }

  .tc-reply-btn {
    position: absolute;
    color: white;
    background-color: #0c0c0d;
    right: 5px;
    top: -6px;
    z-index: 2;
    display: none;
    padding: 0 8px 4px;
    border: none;
    outline: none;
    font-size: 20px;
    line-height: 25px;
  }
    &:hover {
      background-color: #393941;
      .tc-reply-btn {
        display: block;
      }
    }

    .tc-emote {
      max-height: 32px;
      margin: auto;
      object-fit: contain;
    }
  }
}

#tc-replying-to {
  .chat-list {
    height: 40px;
    li {
      background: transparent;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .chat-list {
    height: 299px;
  }
}


@media screen and (max-width: 430px) {
  .list-wrapper {
    margin-bottom: 10px;
  }
}
