.tc-scroll-pauser {
  position: absolute;
  top: 90%;
  left: 30%;
  z-index: 1000;
  background-color: #3d3d40!important;
  border: none;
  outline: none;

}
@media screen and (max-width: 991px) {
  .tc-scroll-pauser {
    top: 80%;
    left: 15%;
  }
}

@media screen and (max-width: 430px) {
  .tc-scroll-pauser {
    left: 20%;
  }
}
