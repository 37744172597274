.list-wrapper {
  position: relative;
}

.tc-spam-condenser {
  .tc-msg div{
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
  }
}

.accordion {
  .accordion-item, .accordion-button {
    background-color: transparent!important;
    color: #fefefe!important;
  }
  .accordion-button {
    padding: 2px 5px;
    width: fit-content;
    background-color: #3d3d40!important;
    &::after {
      filter: contrast(0);
      margin-left: 1em;
    }
  }
  .accordion-item {
    border: none;
    outline: none;
  }
  .accordion-collapse {
    overflow-x: scroll;
  }
}

#spotlight {
  min-width: 150px;
}
