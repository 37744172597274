.tc-event {
    padding: .1em 1em;
    font-size: 80%;
}
.tc-event-type-raid {
    background: #981b1e;
    color: white;
}

.tc-event-type-sub {
    background: #046b99;
    color: white;
}

.tc-event-type-bits {
    background: #2e8540;
    color: white;
}

.tc-event-type-admin {
    color: #adadb8;
    padding-left: 0;
}
