#streamer-data {
  #streamer-avatar {
    width: 50px;
  }

  &.connected {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    display: flex;

    #streamer-avatar {
      border-radius: 50px;
      flex-wrap: wrap;
    }
  }
}

#stream-info {
  .stream-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 991px) {
  #streamer-data {
    #streamer-avatar {
      width: 40px;
    }
  }

  #stream-info {
    max-width: 50%;
    white-space: normal;
  }
}

@media screen and (max-width: 430px) {
  #streamer-data {
    font-size: 75%;

    #streamer-avatar {
      width: 35px;
    }
  }
  #stream-info {
    max-width: 75%;
    .stream-category {
      white-space: nowrap;
      max-width: 135px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
